import type { DynamicAttributeFilter } from '@futureecom/futureecom-js/dist/services/setting-service'
import type { FilterDefinition } from '@/types/FilterDefinitions'

export const dynamicAttributeTypeOptions = [
  { text: 'string', value: 'string' },
  { text: 'text', value: 'text' },
  { text: 'numeric', value: 'numeric' },
  { text: 'boolean', value: 'boolean' },
  { text: 'select', value: 'select' },
  { text: 'select-multiple', value: 'select-multiple' },
  { text: 'checkbox', value: 'checkbox' },
  { text: 'combobox', value: 'combobox' },
  { text: 'money', value: 'money' }
]

export const dynamicAttributeFilters = (resourceTypes: string[]): FilterDefinition<DynamicAttributeFilter>[] => [
  { type: 'text', code: 'code', label: 'Code' },
  { type: 'text', code: 'label', label: 'Label' },
  {
    type: 'select-multiple',
    code: 'type',
    label: 'Type',
    options: dynamicAttributeTypeOptions
  },
  {
    type: 'array',
    code: 'resource_types',
    label: 'Resource Types',
    options: resourceTypes
  },
  { type: 'text', code: 'groups.code', label: 'Groups code' },
  { type: 'text', code: 'groups.label', label: 'Groups label' },
  { type: 'date', code: 'created_at', label: 'Created at' },
  { type: 'date', code: 'updated_at', label: 'Updated at' }
]
